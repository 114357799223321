import { init } from "@telia/b2x-cookie-consent";

const w = typeof window !== "undefined" ? window : undefined;
let ONETRUST_ID: string;
let ONETRUST_SCRIPT_SRC: string;

if ((w && w.location.hostname === "www.telia.se") || (w && w.location.hostname === "telia.se")) {
  ONETRUST_ID = "f88f5b19-b79e-4ecb-bd1d-a6b7b25a41ef";
  ONETRUST_SCRIPT_SRC =
    "https://cdn.cookielaw.org/consent/f88f5b19-b79e-4ecb-bd1d-a6b7b25a41ef/otSDKStub.js";
} else {
  ONETRUST_ID = "f88f5b19-b79e-4ecb-bd1d-a6b7b25a41ef-test";
  ONETRUST_SCRIPT_SRC = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
}

declare global {
  interface Element {
    content?: Cookie.Content;
  }
}

export async function bootstrap(): Promise<void> {
  return;
}

let loaded = false;

export async function mount(): Promise<void> {
  if (!loaded) {
    init(ONETRUST_ID, ONETRUST_SCRIPT_SRC);
    loaded = true;
  }
}

export async function unmount(): Promise<void> {
  return;
}
